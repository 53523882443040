<template>
    <div>
        <loading-spinner v-if="!isPageLoaded"/>
        <div v-else class="row dashboard">
            <dashboard-wizard-starting
                v-if="wizardStarting"
                @close="wizardStarting = false"
            ></dashboard-wizard-starting>

            <div class="dashboard__header" v-if="module.staff_month">
                <staff-month :window-size="windowSize"/>
            </div>
            <div class="dashboard__body">
                <wall/>
                <swiper
                    class="dashboard__widget-slider"
                    :options="swiperOptions"
                    ref="widgets"
                    v-if="windowSize <= 1280"
                >
                    <swiper-slide v-if="stepWidgetShow">
                        <steps-widget/>
                    </swiper-slide>
                    <swiper-slide v-if="module.testing">
                        <testing-widget/>
                    </swiper-slide>
                    <swiper-slide>
                        <wrapper-materials :activeArticlesWidget="true"/>
                    </swiper-slide>
                    <swiper-slide v-if="!current_user.is_admin">
                        <wrapper-materials :activeLearningWidget="true"/>
                    </swiper-slide>
                </swiper>
                <div
                    class="dashboard__widget"
                    v-else
                >
                    <steps-widget v-if="stepWidgetShow"/>
                    <testing-widget v-if="module.testing"></testing-widget>
                    <wrapper-materials :activeArticlesWidget="true" :activeLearningWidget="true"/>
                </div>
            </div>
            <tour v-if="current_user.is_admin && isPageLoaded && !wizardStarting" :tips="tips.admin" :t_name="tips.name"/>
        </div>
    </div>
</template>

<script>
    import session from '@/api/session';
    import {mapState} from "vuex";
    import json from "assets/json/tips.json";

    export default {
        name: 'Dashboard',
        components: {
            LoadingSpinner : () => import('@/components/LoadingSpinner'),
            DashboardWizardStarting : () => import('@/components/wizard-starting/DashboardWizardStarting'),
            WrapperMaterials : () => import('./WrapperMaterials'),
            StepsWidget : () => import('../widgets/Steps'),
            TestingWidget : () => import('../widgets/Testing'),
            ArticlesWidget : () => import('../widgets/Articles'),
            LearningWidget : () => import('../widgets/Learning'),
            Wall : () => import('@/components/wall/Wall'),
            StaffMonth : () => import('./staff-month/StaffMonth'),
            Tour : () => import('@/components/Tour')
        },
        data() {
            return {
                stepWidgetShow: false,
                activeStaffMonthWidget: false,
                swiperOptions: {
                    slidesPerView: 'auto',
                    spaceBetween: 30,
                    centeredSlides: true,
                    containerModifierClass: 'widgets__slider-',
                    slideClass: 'widgets__slide',
                },
                windowSize: document.documentElement.clientWidth,
                wizardStarting: false,
                isPageLoaded: false,
                tips: json.dashboard
            }
        },
        computed: {
            ...mapState('default_data', [
                'module', 'current_user',
            ])
        },
        methods: {
            onResize() {
                this.windowSize = document.documentElement.clientWidth;
            }
        },
        async mounted() {
            let showStep = true;
            await session
                .get('/api/v1/dashboard-widget/steps/')
                .then(response => {
                    response.data.forEach(item => {
                        if (item.hash === '13fd0b93d3aea9c50f870d99241bbd21') {
                            showStep = false;
                        }
                    })
                    this.stepWidgetShow = showStep;
                })
                .catch((errors) => {
                    console.log('errors', errors)
                });
            window.addEventListener('resize', this.onResize);
            this.onResize();
            if (this.$cookies.isKey('wizard_starting')) {
                this.wizardStarting = true;
                this.$cookies.remove('wizard_starting');
            }

            setTimeout(() => {
                if (!!this.module.staff_month) {
                    const tip = this.tips.admin.list.find(item => item.hash === `a02705a7d5c34136ac62d126b129c220`);
                    tip.isDisabled = false;
                }
            }, 3000)
            this.isPageLoaded = true;
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
    }
</script>

<style lang="sass">
    @import "#sass/v-style"

    .dashboard__widget-slider
        width: 100%
        height: 100%
        .widgets__slide
            width: 520px !important
            text-align: center
            font-size: 1.125rem

    .content
        padding: 40px 40px 1px
        p
            margin-bottom: 0

    .dashboard
        font-family: $fonts !important
        color: #000
        padding: 0
        margin: 0
        &__block
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07)
            background: #FFF

        .h2
            font-size: 1.125rem
            color: #000
            margin-bottom: 0

        ul
            list-style: none
            padding: 0
            margin: 0

        &__fa-icon
            font-family: "FontAwesome"
            font-style: normal

        &__header
            width: 100%

        &__body
            display: flex
            width: 100%
            max-width: 100%
            margin: 0 auto
            justify-content: space-between
            @media (max-width: 1280px)
                flex-direction: column

        &__wall
            max-width: 1100px
            min-width: 65%
            flex-grow: 1
            margin-right: 25px

        &__widget
            max-width: 550px
            width: 35%
            display: flex
            flex-direction: column
            margin-right: auto

            &-slider
                order: -1

    .widget__item
        margin-bottom: 25px
        padding: 30px
        &:empty
            display: none

    @media (min-width: 1281px) and (max-width: 1366px)
        .dashboard__widget
            max-width: 350px
        .widget__steps .steps__btn
            margin-left: 0 !important

    @media (max-width: 1280px)
        .content
            padding: 20px 0
        .dashboard__wall
            padding: 0 30px
            margin-right: 0
        .widgets__slide
            min-width: 520px
        .staff-month
            padding: 0px 30px
    @media (max-width: 500px)
        .dashboard .h2
            font-size: 1rem !important
        .widgets__slide
            max-width: 285px
            min-width: 285px
            font-size: 1rem
        .dashboard__wall
            padding: 0px 15px
            margin-right: 0
        .staff-month
            padding: 0px 15px

</style>
